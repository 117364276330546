var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Main',{attrs:{"full-height":""}},[_c('div',{staticClass:"d-flex flex-column justify-center margin-x-auto max-w-800 text-center h-full padding-b-4"},[_c('a',{attrs:{"href":"https://www.charterup.com"}},[_c('CharterUPLogo',{staticClass:"margin-x-auto max-w-300 cursor-pointer"})],1),_c('CUIcon',{staticClass:"margin-x-auto margin-y-4",class:{
        'min-w-48 min-h-48 w-48 h-48': _vm.$vuetify.breakpoint.smAndUp,
        'min-w-32 min-h-32 w-32 h-32': _vm.$vuetify.breakpoint.xsOnly,
      },attrs:{"view-box":"0 0 24 24","color":"success"}},[_vm._v(" check_circle ")]),_c('h3',{staticClass:"margin-b-4",class:{
        'font-24': _vm.$vuetify.breakpoint.smAndUp,
        'font-18': _vm.$vuetify.breakpoint.xsOnly,
      }},[_vm._v(" Thanks for completing your CharterUP reservation! "),_c('br'),_vm._v(" We're looking forward to traveling with you. ")]),_c('p',[_vm._v(" A confirmation email with details of your itinerary is on its way now. If you have any questions please contact us at "),_c('a',{attrs:{"href":"mailto:sales@charterup.com"}},[_vm._v("sales@charterup.com")]),_vm._v(" or "),_c('a',{attrs:{"href":"tel:8559202287"}},[_vm._v("(855) 920-2287")]),_vm._v(" . ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }